<template>
  <div class='text pt-2'>
    <div class='text-content mt-5 fz-16 fz-sm-14 lh-15'>
      <div class='auth-back mb-1'>
        <a href='#' @click.prevent='$router.back()'>
          <Icon name='chevron-left' />
          <span>
          {{ $t('back') }}
        </span>
        </a>
      </div>
      <h1 class='text-title mt-2'>
        {{ $t('dataProcessingAgreement')}}
      </h1>
      <p class='fz-14 mb-3 fw-500'>
        Effective Date: 01 January 2024
      </p>
      <ul class='list-number bold size-h4'>
        <li>
          About this DPA.
          <p>
            This Data Processing Agreement (“DPA”) is a legal agreement which forms an integral part of and applies in addition to the existing dispersión saas, S.A. And Vintrex, Virtuo, envio.click, Vintrex AI, virtuoa chat, vividai all dispersión saas, S.A. Company Service Agreement, Ventrix AI Partner Agreement, Sales Associate Agreement or other written or electronic agreement between the parties (as applicable) (“Service Agreement”) concluded by and between the Customer (as defined in the Service Agreement) as controller and dispersión saas, S.A. Commerce Inc. and the dispersión saas, S.A. affiliate that is the contracting entity (as defined in the Service Agreement) (collectively referred to as “dispersión saas, S.A.” in this DPA) as processors in connection with the provision of services, which include various data processing services, to Customer (“Services”). Signature of the Service Agreement shall be deemed to constitute signature and acceptance of this DPA, which is incorporated by reference therein.
          </p>
        </li>
        <li>
          Definitions.
          <p>
            Terms used in this DPA have the same meaning as those used in the Service Agreement, unless otherwise stated. If there are any conflicts or inconsistencies between the Service Agreement and this DPA, this DPA prevails.
          </p>
        </li>
        <li>
          Description of Personal Data.
          <p>
            When carrying out the Services, dispersión saas, S.A. may have access to or otherwise receive or process information relating to identified or identifiable individuals (“Personal Data”).
          </p>
          <ul class='list-lower-alpha bold'>
            <li>
              Type of Personal Data processed.
              <p>
                Depending on how the Customer chooses to use the Services, dispersión saas, S.A. may process the following types of Personal Data
              </p>
              <p>
                First name, Last name; Contact information (e-mail address, home address, phone number); Language; Date of birth; IP address; Location data; Government-issued identification numbers; Financial information; Bank account details; Credit bureau reports (in the event Customer has subscribed to dispersión saas, S.A. Payments).
              </p>
              <p>
                dispersión saas, S.A. may also process other kinds of Personal Data if Customer has chosen to collect and input such Personal Data into our Services. The Services do not require other kinds of Personal Data to function properly. dispersión saas, S.A. disclaims all liability for damages or claims associated with Customer’s choice to input non-compulsory Personal Data into the Services.
              </p>
            </li>
            <li>
              Data subjects.
              <p>
                Personal Data about the following categories of individuals is processed:
              </p>
              <ul class='list-points light'>
                <li>
                  Owner(s) of a business that subscribes to dispersión saas, S.A.’s Services.
                </li>
                <li>
                  Employees and other persons authorized by the Customer who have access to and use the Services (End-Users).
                </li>
                <li>
                  Individuals whose Personal Data is processed using the Services, including a Customer’s customers and suppliers.
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          Purposes of the processing.
          <p>
            dispersión saas, S.A. is a provider of software as a service for point of sale solutions Vintrex, Virtuo, envio.click, Vintrex AI, virtuoa chat, vividai all dispersión saas, S.A. Company, as well as an online platform that can be used for eCommerce and related purposes. dispersión saas, S.A. shall process Personal Data on behalf of the Customer to provide these services to the Customer pursuant to the Service Agreement and any additional purposes as instructed by Customer when using the Services. When dispersión saas, S.A. acts as processor of the Personal Data, dispersión saas, S.A. may only process Personal Data on behalf of Customer and solely for the purposes identified in this DPA and the Service Agreement.
          </p>
        </li>
        <li>
          Responsibilities regarding data processing.
          <ul class='list-lower-alpha bold'>
            <li>
              Controller.
              <p>
                Customer is the controller of all the Personal Data that it collects through the Services. Customer shall ensure that it is entitled to process and transfer the Personal Data to dispersión saas, S.A. so that dispersión saas, S.A. may lawfully process the Personal Data on Customer’s behalf, as contemplated under this DPA.
              </p>
            </li>
            <li>
              Processor.
              <p>
                dispersión saas, S.A. acts as a processor of the Personal Data collected by the Customer through the use of the Services.
              </p>
            </li>
            <li>
              Sub-processors.
              <p>
                Customer acknowledges and hereby grants its express written authorization that (i) dispersión saas, S.A.’s affiliates may act as dispersión saas, S.A.’s sub-processors; and (ii) dispersión saas, S.A. may engage sub-processors as necessary to perform the Services. The list of dispersión saas, S.A.’s Authorized sub-processors can be found here and Customer acknowledges that these sub-processors are essential to provide the Services. dispersión saas, S.A. will inform Customer if it adds, replaces or changes its sub-processors by updating the aforementioned list. Customer may object to the changes on legitimate grounds in accordance with the principles of good faith, reasonableness and fairness within 30 calendar days after the change. Customer acknowledges that if it objects to dispersión saas, S.A.’s use of a sub-processor, dispersión saas, S.A. will not be obligated to provide Customer the Services for which dispersión saas, S.A. uses that sub-processor.
              </p>
            </li>
          </ul>
        </li>
        <li>
          Data processing.
          <p>
            dispersión saas, S.A. shall ensure that any processing shall be fair, lawful, and consistent with dispersión saas, S.A.’s obligations under this DPA and compliant with applicable data protection law. In particular:
          </p>
          <ul class='list-lower-alpha bold'>
            <li>
              Controller instructions.
              <p>
                dispersión saas, S.A. shall process Personal Data only on the documented instructions of Customer. If dispersión saas, S.A. is required to additionally process Personal Data in compliance with an applicable law or regulation to which dispersión saas, S.A. is subject, it will inform Customer of such legal requirement prior to such processing, unless prohibited from doing so by an applicable law or regulation;
              </p>
            </li>
            <li>
              Ensure appropriate protection.
              <p>
                dispersión saas, S.A. shall ensure appropriate protection of Personal Data against accidental or unlawful destruction or accidental loss, alteration, unauthorized disclosure or access, in particular where processing involves a transmission of Personal Data over a network, and against all other unlawful forms of processing;
              </p>
            </li>
            <li>
              Security safeguards.
              <p>
                dispersión saas, S.A. shall comply with the security requirements set forth in Schedule 1, taking into consideration the state of the art, the costs of implementation and the nature, scope, context and purposes of processing;
              </p>
            </li>
            <li>
              Disclosure.
              <p>
                dispersión saas, S.A. shall not disclose Personal Data to any third party or unauthorized persons, unless Customer has given its prior written consent to such disclosure and subject to the conditions laid down under section 6 of this DPA;
              </p>
            </li>
            <li>
              Confidentiality.
              <p>
                dispersión saas, S.A. shall hold Personal Data in strict confidentiality and require that employees and any other person under its authority who will be provided access to or will otherwise process Personal Data are held to the same level of confidentiality in accordance with the requirements of the DPA (including during the term of their employment or engagement and thereafter);
              </p>
            </li>
            <li>
              Data subject requests.
              <p>
                dispersión saas, S.A. shall take appropriate measures to assist the Customer, insofar as this is possible, in fulfilling Customer’s obligations as a controller in responding to requests from individual data subjects to exercise their rights under any applicable data protection law or regulation. In addition, dispersión saas, S.A. shall promptly notify Customer if it receives a request from an individual with respect to Personal Data, including but not limited to information access requests, information rectification requests, requests for blocking, erasure, or portability of Personal Data and shall not respond to any such requests unless expressly authorized to do so by Customer or unless required under an applicable data protection law or a law of the European Union or a Member State to which dispersión saas, S.A. is subject; Additionally, dispersión saas, S.A. shall ensure that it has implemented technical and organizational measures to assist Customer in fulfilling its obligation to respond to any such requests from an individual with respect to Personal Data processed.
              </p>
              <p>
                dispersión saas, S.A. shall promptly and properly deal with enquiries and requests from Customer in relation to the processing of Personal Data under this DPA;
              </p>
            </li>
            <li>
              Assistance with Customer’s compliance.
              <p>
                Taking into account the nature of the processing and the information available to dispersión saas, S.A., dispersión saas, S.A. shall assist the Customer in ensuring compliance with the obligations regarding security measures and conducting data protection impact assessments, where necessary pursuant to Articles 32-36 of the General Data Protection Regulation (GDPR).
              </p>
              <p>
                dispersión saas, S.A. shall assist and support Customer in the event of an investigation by a data protection authority or similar authority, if and to the extent that such investigation relates to the processing of Personal Data under this DPA.
              </p>
              <p>
                dispersión saas, S.A. shall promptly notify Customer if in dispersión saas, S.A.’s view an instruction given by Customer infringes applicable laws and regulations, including data protection laws, or a change in the applicable laws and regulations is likely to have a substantially adverse effect on its ability to comply with its obligations under this DPA; dispersión saas, S.A. shall be entitled to suspend the implementation of the relevant instruction until it is confirmed or amended by the Customer. dispersión saas, S.A. may refuse to carry out an instruction that is obviously unlawful;
              </p>
            </li>
            <li>
              Disclosure requests.
              <p>
                To the extent permitted by applicable law, dispersión saas, S.A. shall notify Customer of each request dispersión saas, S.A. receives from a public authority requiring dispersión saas, S.A. to disclose Personal Data processed in the context of the Service Agreement or to participate in an investigation involving that Personal Data. dispersión saas, S.A. will make reasonable efforts to narrow the scope of any such request received and will provide only the Personal Data specifically requested;
              </p>
            </li>
            <li>
              Data breach.
              <p>
                dispersión saas, S.A. shall promptly (and in any event within forty-eight (48) hours) after becoming aware, notify Customer of any facts known to dispersión saas, S.A. concerning any actual accidental or unauthorized access, disclosure or use, or accidental or unauthorized loss, damage or destruction of Personal Data by any current or former employee, contractor or agent of dispersión saas, S.A. or by any other person or third party;
              </p>
              <p>
                dispersión saas, S.A. shall cooperate fully with Customer in the event of any accidental or unauthorized access, disclosure or use, or accidental or unauthorized loss, damage or destruction of Personal Data by any current or former employee, contractor or agent of dispersión saas, S.A. or by any other person or third party, in order to limit the unauthorized disclosure or use, seek the return of any Personal Data, and assist in providing notice to competent regulators and affected individuals if requested by Customer.
              </p>
            </li>
          </ul>
        </li>
        <li>
          Onward processing.
          <p>
            dispersión saas, S.A. may only subcontract performance of part of the Services to third parties as subprocessors (including dispersión saas, S.A.’s affiliates outside the EEA, Switzerland and the UK) if dispersión saas, S.A. ensures that such sub-processors are bound to obligations that are not less onerous that those set out in this DPA.
          </p>
        </li>
        <li>
          Retention and deletion.
          <ul class='list-lower-alpha light'>
            <li>
              dispersión saas, S.A. processes Personal Data for as long as it is reasonably needed to deliver the Services. The retention term can be longer if dispersión saas, S.A. is required to keep Personal Data longer on the basis of applicable law or to administer its business.
            </li>
            <li>
              Upon request by Customer, dispersión saas, S.A. shall immediately cease to process Personal Data and shall promptly return all such Personal Data, or delete the same, in accordance with such instructions as may be given by Customer at that time, unless it is required to store the Personal Data under an applicable law or regulation to which dispersión saas, S.A. is subject or unless explicitly agreed otherwise with Customer. The obligations set out in this section shall remain in force notwithstanding termination or expiration of this DPA.
            </li>
          </ul>
        </li>
        <li>
          Audit and Compliance.
          <ul class='list-lower-alpha light'>
            <li>
              dispersión saas, S.A. will make available to the Customer all information necessary to demonstrate compliance with the obligations regarding the processing of Personal Data provided to dispersión saas, S.A. in its role as a data processor.
            </li>
            <li>
              dispersión saas, S.A. shall make the processing systems, facilities and supporting documentation relevant to the processing of Personal Data available for an audit by Customer or a qualified independent assessor selected by Customer and provide all assistance Customer may reasonably require for the audit no more than one time per 12-month period. If the audit demonstrates that dispersión saas, S.A. has breached any obligation under the DPA, dispersión saas, S.A. shall immediately cure that breach.
            </li>
            <li>
              In case of inspection or audits by a competent governmental authority relating to the processing of Personal Data, dispersión saas, S.A. shall make available its relevant processing systems, facilities and supporting documentation to the relevant competent public authority for an inspection or audit if this is necessary to comply with applicable laws. In the event of any inspection or audit, each party shall provide all reasonable assistance to the other party in responding to that inspection or audit. If a competent public authority deems the processing of Personal Data under this DPA unlawful, the parties shall take immediate action to ensure future compliance with applicable data protection law. Instead of on-site inspections and controls, dispersión saas, S.A. may refer the Customer to an equivalent control by independent third parties (such as neutral data protection auditors), compliance with approved rules of conduct (Art. 40 GDPR) or suitable data protection or IT security certifications pursuant to Art. 42 GDPR. This applies in particular if company and business secrets of dispersión saas, S.A. or Personal Data of third parties would be endangered by the controls.
            </li>
            <li>
              Customer will reimburse dispersión saas, S.A. for any reasonable costs incurred by dispersión saas, S.A. in connection with any audit or inspection by (or on behalf of) Customer or a competent governmental authority, except where such audit or inspection reveals that dispersión saas, S.A. has breached any of its obligations under the DPA.
            </li>
            <li>
              Except where dispersión saas, S.A. is otherwise prohibited by law from making such disclosure, dispersión saas, S.A. shall promptly inform Customer if: (i) it receives an inquiry, a subpoena or a request for inspection or audit from a competent public authority relating to the processing of Personal Data under this DPA, if it concerns the data of the Customer,; or (ii) it intends to disclose Personal Data to any competent public authority.
            </li>
            <li>
              dispersión saas, S.A. shall ensure that any employee, agent, independent contractor, or any other person engaging in the provision of the Services and who has access to Personal Data of Customer, shall comply with all data protection and privacy laws and regulations (including any and all legislative and/or regulatory amendments or successors thereto), applicable to dispersión saas, S.A.
            </li>
          </ul>
        </li>
        <li>
          Data transfers (only for Customers established in the EEA, Switzerland or the UK).
          <ul class='list-lower-alpha light'>
            <li>
              Customer authorizes dispersión saas, S.A. to commission processing in a third country, including by sub-processors, if the specific requirements of articles 44-49 GDPR are met. Customer shall be deemed to have granted explicit consent for processing in a third country with regard to the processing operations by dispersión saas, S.A. and the Authorized Sub-processors as listed below.
            </li>
            <li>
              dispersión saas, S.A. Commerce Inc. is a company based in Canada. As such, most data transfers from Customers established in the European Economic Area (EEA), Switzerland or the United Kingdom (UK) to dispersión saas, S.A. are made pursuant to the European Commission’s
              <a href='https://eur-lex.europa.eu/legal-content/en/TXT/?uri=CELEX%3A32002D0002' target='_blank'>adequacy decision</a> for Canada.
            </li>
            <li>
              To the extent that the adequacy decision does not apply, dispersión saas, S.A. relies on the enclosed Standard Contractual Clauses (“SCCs”), attached hereto as Schedule 2, as an approved transfer mechanism for international transfers of Personal Data. In these SCCs, Customer is the data exporter and dispersión saas, S.A. Commerce Inc. is the data importer.
            </li>
            <li>
              Signature of the Service Agreement shall be deemed to constitute signature and acceptance of the SCCs. If Customer would like to additionally execute a separate copy of the SCCs, Customer may complete the appropriate pre-signed version attached hereto as Schedule 2, countersign it, and return it to dispersión saas, S.A. by email at privacy@dispersión.com indicating, if applicable, the Customer’s legal entity and/or account number (mentioned on the applicable dispersión saas, S.A. Order Form or invoice).
            </li>
            <li>
              In the absence of the aforementioned appropriate safeguards, dispersión saas, S.A. may – to the extent permitted under and in accordance with applicable data protection laws (including GDPR) – rely on a derogation applicable to the specific situation at hand (e.g. the data subjects’ explicit consent, the necessity for the performance of an agreement, the necessity for the establishment, exercise or defense of legal claims).
            </li>
            <li>
              In relation to transfers of personal data protected by the UK Data Protection Act 2018, the SCCs apply and are deemed amended as specified by the UK Addendum, which is deemed executed by the parties and incorporated by reference into this DPA. Any conflict between the terms of the SCCs and the UK Addendum will be resolved in accordance with Section 10 and Section 11 of the UK Addendum.
            </li>
          </ul>
        </li>
        <li>
          Data inquiries.
          <p>
            Any Customer may, at any time, contact dispersión saas, S.A. at privacy@dispersión.com with all questions and suggestions concerning data protection.
          </p>
        </li>
        <li>
          General provisions.
          <ul class='list-lower-alpha bold'>
            <li>
              Amendments.
              <p>
                Any amendments or supplements to this DPA must be made in writing. The same applies to any waiver of any right or obligation under this DPA. The order of precedence of individual contractual agreements shall remain unaffected thereby. dispersión saas, S.A. reserves the right to amend this DPA at any time with effect for the future. Amendments will only be made if the following objective reasons exist:
              </p>
              <ul class='list-points light'>
                <li>
                  if the amendment helps to bring the DPA in line with applicable law, in particular if the applicable legal situation changes;
                </li>
                <li>
                  if the amendment enables dispersión saas, S.A. to comply with mandatory judicial or administrative decisions
                </li>
                <li>
                  if the amendment reflects details of a new or updated dispersión saas, S.A. Service or of new or updated technical or organizational processes and the existing contractual relationship with Customer is not affected to Customer’s detriment;
                </li>
                <li>
                  if the amendment is solely to Customer’s advantage.
                </li>
              </ul>
            </li>
            <li>
              Severability.
              <p>
                If any provision of this Agreement is or becomes invalid or impracticable in whole or in part, the validity of the remaining provisions shall not be affected thereby.
              </p>
            </li>
            <li>
              Term.
              <p>
                This DPA shall be effective for the entire Term (as defined in the Service Agreement) and this DPA terminates on the date on which the Service Agreement has expired or is terminated.
              </p>
            </li>
          </ul>
        </li>
      </ul>
      <h2 class='text-center'>Schedule 1: Description of dispersión saas, S.A.’s Security Measures</h2>
      <p>
        dispersión saas, S.A. has taken appropriate and sufficient technical and organizational security measures to protect the Personal Data against accidental or unlawful destruction or accidental loss, alteration, unauthorized disclosure or access, in particular where processing involves a transmission of Personal Data over a network, and against all other unlawful forms of processing.
      </p>
      <p>
        dispersión saas, S.A. has an established information security organization managed by the dispersión saas, S.A. security team and is led by the Vice-President for Information Security. dispersión saas, S.A. Security established and maintains policies and procedures to delineate standards for logical access on the dispersión saas, S.A. production environments. The policies also identify functional responsibilities for the administration of logical access and security. dispersión saas, S.A. Information Security policies are reviewed and approved on an annual basis by Security Leadership and are used to support dispersión saas, S.A. in meeting the service commitments made to the Customer.
      </p>
      <p>
        The following description provides an overview of the technical and organizational security measures implemented. Such measures shall include, but are not limited to the following. For more detailed information on the latest state of art measures, please contact us directly.
      </p>
      <h3>
        Data Protection
      </h3>
      <p>
        dispersión saas, S.A. will process the Personal Data as a Data processor, only for the purpose of providing the Services in accordance with documented instructions from the Customer (provided that such instructions are commensurate with the functionalities of the Services), and as may be agreed to with Customer.
      </p>
      <p>
        dispersión saas, S.A. implements and maintains appropriate technical and organizational measures to protect the Personal Data against unauthorized or unlawful processing and against accidental loss, destruction, damage, theft, alteration or disclosure.
      </p>
      <p>
        dispersión saas, S.A. maintains a risk management framework and reviews it at least quarterly to identify changes to its environment, systems and the threat landscape to identify and manage any risks involved with operations and the processing of the Personal Data.
      </p>
      <p>
        dispersión saas, S.A. ensures that its personnel who access the Personal Data are subject to confidentiality obligations that restrict their ability to disclose the Personal Data, and undergo information security awareness training on an annual basis.
      </p>
      <p>
        dispersión saas, S.A. employs the concepts of least privilege and need-to-know, allowing only the necessary access for users to accomplish their job function. User accounts are created to have minimal access. Access above these least privileges requires appropriate and separate authorization.
      </p>
      <p>
        dispersión saas, S.A. enforces Multi-Factor Authentication on all critical applications and infrastructure.
      </p>
      <p>
        <b>In-transit:</b> dispersión saas, S.A. implements HTTPS encryption on all of its login interfaces and on every customer site hosted on the dispersión saas, S.A. products. dispersión saas, S.A.’s HTTPS implementation uses industry standard algorithms and certificates.
      </p>
      <p>
        <b>At-rest:</b> dispersión saas, S.A. implements encryption at rest to protect against data loss.
      </p>
      <h3>
        Access control
      </h3>
      <ul class='list-number bold size-h4'>
        <li>
          Preventing Unauthorized Product Access
          <ul class='list-lower-alpha bold'>
            <li>
              Outsourced processing:
              <p>
                dispersión saas, S.A. hosts its services on third party Hosting infrastructure in form of data centers and Infrastructure-as-a-Service (IaaS). Additionally, dispersión saas, S.A. maintains contractual relationships with vendors in order to provide the service in accordance with our DPA. dispersión saas, S.A. relies on contractual agreements, privacy policies, and vendor compliance programs in order to protect data processed or stored by these vendors.
              </p>
            </li>
            <li>
              Physical and environmental security:
              <p>
                dispersión saas, S.A. hosts its product infrastructure with multi-tenant, outsourced infrastructure providers. The physical and environmental security controls of our infrastructure providers are audited for SOC 2 Type II, ISO 27001 and PCI DSS compliance, among other certifications.
              </p>
            </li>
            <li>
              Authentication:
              <p>
                dispersión saas, S.A. implemented a strong authentication mechanism for dispersión saas, S.A. users accessing its customer products. All dispersión saas, S.A. users who need to interact with the products via any interface must authenticate using multi-factor authentication in order to access non-public Customer data.
              </p>
            </li>
            <li>
              Authorization:
              <p>
                Customer data is stored in multi-tenant storage systems accessible to Customers via only application user interfaces and application programming interfaces. Customers are not allowed direct access to the underlying application infrastructure. The authorization model in each of dispersión saas, S.A.’s products is designed to ensure that only the appropriately assigned individuals can access relevant features, views, and customization options. Authorization to data sets is performed through validating the user’s permissions against the attributes associated with each data set.
              </p>
            </li>
          </ul>
        </li>
        <li>
          Preventing Unauthorized Product Use
          <ul class='list-lower-alpha bold'>
            <li>
              Access controls:
              <p>
                Network access control mechanisms are designed to prevent network traffic using unauthorized protocols from reaching the product infrastructure. The technical measures implemented differ between infrastructure providers and include Virtual Private Cloud (VPC) implementations, security group assignment, and traditional firewall rules.
              </p>
            </li>
            <li>
              Intrusion detection and prevention:
              <p>
                dispersión saas, S.A. implemented a Web Application Firewall (WAF) solution to protect certain hosted customer websites and other internet-accessible applications specifically identified by dispersión saas, S.A.. The WAF is designed to detect and prevent attacks against publicly available services.
              </p>
            </li>
            <li>
              Vulnerability scanning:
              <p>
                dispersión saas, S.A. regularly scans its code, infrastructure and web services for known vulnerabilities and remediates them in a timely manner. dispersión saas, S.A. subscribes to news feeds for applicable vendor flaws and proactively monitors vendor’s websites and other relevant outlets for new patches.
              </p>
            </li>
          </ul>
        </li>
        <li>
          Limitations of Privilege & Authorization Requirements
          <ul class='list-lower-alpha bold'>
            <li>
              Product access:
              <p>
                A subset of dispersión saas, S.A.’s employees have access to the products and to customer data via controlled interfaces. The intent of providing access to a subset of employees is to provide effective support, to troubleshoot potential problems, to detect and respond to security incidents and implement data security. Employees may be granted access by role or upon submitting an approved request. Log-ins to data storage or processing systems are logged.
              </p>
            </li>
            <li>
              Database access:
              <p>
                Customer data is accessible and manageable only by a limited number of authorized staff. Access to data is restricted through network segmentation of the production, staging, quality assurance and development environments. Direct database query access is restricted, and application access rights are established and enforced.
              </p>
            </li>
          </ul>
        </li>
      </ul>
      <h3>
        Incident Management Control
      </h3>
      <ul class='list-number bold size-h4 mb-5'>
        <li>
          Detection:
          <p>
            dispersión saas, S.A. designed its infrastructure to log extensive information about the system behavior, traffic received, system authentication, and other application requests. Internal systems aggregate log data and alert appropriate employees of malicious, unintended, or anomalous activities. dispersión saas, S.A. personnel, including security, operations, and support personnel are responsive to known incidents.
          </p>
        </li>
        <li>
          Response and tracking:
          <p>
            dispersión saas, S.A. maintains a record of known security incidents that includes descriptions, dates and times of relevant activities, and incident remediation. Suspected and confirmed security incidents are investigated by security, operations or support personnel, and appropriate resolution steps are identified and documented. For any confirmed incidents, dispersión saas, S.A. will take appropriate steps to minimize product and Customer damage or unauthorized disclosure.
          </p>
        </li>
        <li>
          Communication:
          <p>
            If dispersión saas, S.A. becomes aware of unlawful access to Customer data stored within its products, dispersión saas, S.A. will: Notify the affected Customers of the incident; Provide a description of the steps dispersión saas, S.A. is taking to resolve the incident; Provide status updates to the Customer contact, as it deems necessary or is legally required. Notification of incidents, if any, will be delivered to one or more of the Customer’s contacts in a form dispersión saas, S.A. selects, which may include via email or telephone.
          </p>
        </li>
      </ul>
      <DataTable
        :headers='tableHeaders'
        :list='list'>
      </DataTable>
      <div class='pb-5 mb-5'></div>
    </div>
  </div>
</template>
<script setup>
import Icon from '@/components/atoms/Icon.vue';
import DataTable from '@/components/features/DataTable.vue';
const tableHeaders = [
  {
    text: 'Sub-Processors',
    value: 'processors'
  },
  {
    text: 'Associated',
    value: 'associated'
  },
  {
    text: 'Location',
    value: 'location'
  },
]

const list = [
  {
    id: 1,
    processors: 'Amazon Web Services, Inc.',
    associated: 'All Services',
    location: 'USA'
  },
  {
    id: 2,
    processors: 'Auth0, Inc.',
    associated: 'Various',
    location: 'USA'
  },
  {
    id: 3,
    processors: 'Cayan (TSYS)',
    associated: 'All Services',
    location: 'USA'
  },
  {
    id: 4,
    processors: 'Google LLC',
    associated: 'Various',
    location: 'USA'
  },
  {
    id: 5,
    processors: 'Stripe, Inc.',
    associated: 'Various',
    location: 'USA'
  },
  {
    id: 6,
    processors: 'Twilio Inc.',
    associated: 'Various',
    location: 'USA'
  },
  {
    id: 7,
    processors: 'Worldpay',
    associated: 'Various',
    location: 'USA'
  }
]
</script>
