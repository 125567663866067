<template>
  <table class="table" :class="{simple: simpleType}" v-bind="$attrs">
    <thead class="table-head" v-if="!simpleType">
      <tr v-if="!hideHeaders" class="table-row">
        <th v-if="checkboxes && list.length" class="table-col">
          <BaseCheckbox
            class="table-checkbox"
            name="select-all-list"
            id="select-all-list"
            :checked="isCheckAll"
            @on-update="changeSelectAll"
          >
          </BaseCheckbox>
        </th>
        <th v-for="item in headers" class="table-col" :key="item.text">
          {{ item.text }}
        </th>
      </tr>
      <Loader v-show="isLoading" type="linear" position="bottom" size="small" />
    </thead>
    <tbody class="table-body">
      <tr
        class="table-row"
        v-for="(item, index) in list"
        :key="index"
        :class="rowClassGenerator ? rowClassGenerator(item) : ''"
      >
        <td v-if="checkboxes" :data-text="$t('select')" class="table-col">
          <BaseCheckbox
            class="table-checkbox"
            :name="item.id"
            :id="item.id"
            :checked="!!selectedCheckboxes.find(e => e.id === item.id)"
            :value="item"
            @on-update="updateCheckboxes"
          >
          </BaseCheckbox>
        </td>
        <td class="table-col" v-for="col in headers" :data-text="col.text" :key="`${col.value}-${index}`">
          <div class="table-col__inner">
            <slot :name="col.value" :item="item" :index="index">{{ item[col.value] }}</slot>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <div
    class="table-search-empty"
    v-if="!withoutEmptyLabel"
    v-show="!list.length && !isLoading && !Object.keys(list).length"
  >
    {{ $t('noData') }}
  </div>
</template>
<script>
import BaseCheckbox from '@/components/atoms/BaseCheckbox';
import Loader from '@/components/atoms/Loader';

import {differenceBy, pullAllBy} from 'lodash';
import {ref, toRefs, watch} from 'vue';

export default {
  props: {
    headers: {
      type: Object
    },
    hideHeaders: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: () => []
    },
    search: {
      type: String,
      default: ''
    },
    checkboxes: {
      default: false
    },
    simpleType: {
      type: Boolean,
      default: false
    },
    withoutEmptyLabel: {
      type: Boolean,
      default: false
    },
    hasLocalFilter: {
      type: Boolean,
      default: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    page: {
      type: Number,
      default: 1
    },
    selectedItems: {
      type: Array,
      default: () => []
    },
    rowClassGenerator: {
      type: Function,
      default: null
    }
  },
  components: {
    BaseCheckbox,
    Loader
  },
  emits: ['update-checkboxes'],
  setup(props, {emit}) {
    const selectedCheckboxes = ref([]);
    const isCheckAll = ref(false);
    const {list, page, selectedItems} = toRefs(props);
    selectedCheckboxes.value = selectedItems.value;

    const updateCheckboxes = item => {
      if (!selectedCheckboxes.value.length) {
        selectedCheckboxes.value.push(item);
      } else {
        const findIndex = selectedCheckboxes.value.findIndex(e => e.id === item.id);
        if (findIndex < 0) {
          selectedCheckboxes.value.push(item);
        } else {
          selectedCheckboxes.value.splice(findIndex, 1);
        }
      }
      emit('update-checkboxes', selectedCheckboxes.value);
    };

    watch(page, () => {
      isCheckAll.value = false;
    });

    const changeSelectAll = () => {
      isCheckAll.value = !isCheckAll.value;
      if (isCheckAll.value) {
        const differenceWithList = differenceBy(list.value, selectedCheckboxes.value, 'id');
        if (differenceWithList.length) {
          selectedCheckboxes.value.push(...differenceWithList);
        }
      } else {
        const pulledList = pullAllBy(selectedCheckboxes.value, list.value, 'id');
        if (pulledList.length) {
          selectedCheckboxes.value = pulledList;
        }
      }
      emit('update-checkboxes', selectedCheckboxes.value);
    };

    return {
      updateCheckboxes,
      selectedCheckboxes,
      changeSelectAll,
      isCheckAll
    };
  }
};
</script>
<style lang="scss">
.table {
  width: 100%;
  &:not(.simple) {
    .table-col {
      @include respondTo(600px) {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: right;
        font-size: 12px;
        line-height: 1.4;
        &:nth-child(even) {
          background: $silver-3;
        }
        &::before {
          text-align: left;
          content: attr(data-text) ':';
          font-size: 12px;
          font-weight: 600;
          margin-right: 30px;
        }
      }
    }
    .table-row {
      @include respondTo(600px) {
        display: block;
        margin-bottom: 20px;
        border: 1px solid $silver-2;
        border-radius: 3px;
      }
    }
  }
  &-head {
    position: relative;
    width: 100%;
    background: #f2f2f2;
    border-radius: 4px 4px 0 0;
    @include respondTo(600px) {
      display: none;
    }
    th {
      font-size: 12px;
      font-weight: 600;
      white-space: nowrap;
    }
  }
  &-body {
    width: 100%;
    background: $white;
    tr {
      transition: background 0.05s ease;
      &.red-row {
        background: rgba($red, 0.1);
        &:hover {
          background: rgba($red, 0.15);
        }
      }
      &:hover {
        background: rgba(0, 0, 0, 0.03);
      }
    }
  }
  &-row {
    width: 100%;
    border-bottom: 1px solid $silver-2;
    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  &-col {
    padding: 10px 15px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  &-search-empty {
    font-size: 14px;
    font-weight: 500;
    padding: 10px 15px;
    @include respondTo(600px) {
      padding: 10px 0;
    }
  }
  &-checkbox {
    @include respondTo(600px) {
      label {
        padding-left: 17px;
      }
    }
  }
  &-action {
    &__btn {
      margin-right: 10px;
      i {
        width: 18px;
        height: 18px;
      }
      &.readonly {
        pointer-events: none;
        opacity: 0.5;
      }
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
      @include respondTo(600px) {
        margin-right: 0;
        margin-left: 15px;
      }
      svg {
        color: $silver;
        stroke: $silver;
        transition: stroke $trans, color $trans;
      }
      &:hover {
        svg {
          color: $primary;
          stroke: $primary;
        }
      }
    }
  }
  .chip {
    span {
      display: inline-block;
      padding: 3px 13px;
      border-radius: 10em;
      border: 1px solid $primary;
    }
  }
  .status {
    text-transform: capitalize;
    span {
      display: inline-block;
      &.status-unsubscribed {
        background: $red;
      }
      &.status-subscribed {
        background: $primary;
      }
      &.status-active {
        background: $primary;
      }
      &.status-inactive {
        background: $red;
      }
      &.status-0 {
        background: $red;
      }
      &.status-1 {
        background: $primary;
      }
      &.status-2 {
        background: $orange;
      }
      &.status-3 {
        background: $orange;
      }
      width: 7px;
      height: 7px;
      border-radius: 50%;
      margin-right: 5px;
      margin-bottom: 1px;
    }
  }
}
.simple {
  .table-head {
    display: none;
  }
  .table-row {
    position: relative;
    border-bottom: none;
    border-radius: 7px;
    &:hover {
      background: transparent;
    }
    @include respondTo(1100px) {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding-bottom: 20px;
    }
  }
  .table-col {
    background: #fff;
    position: relative;
    @include respondTo(1100px) {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-bottom: 0;
      &:first-child {
        width: 40%;
      }
      &:nth-child(2) {
        width: 30%;
      }
      &:nth-child(3) {
        width: 30%;
      }
      &:nth-child(4) {
        width: 30%;
      }
      &:nth-child(5) {
        width: 30%;
      }
      &:nth-child(6) {
        width: 30%;
      }
      &:nth-child(7) {
        width: 10%;
      }
      &__inner {
        padding: 20px;
      }
    }
    @include respondTo(580px) {
      &:first-child {
        width: 50%;
      }
      &:nth-child(2) {
        width: 50%;
      }
      &:nth-child(3) {
        width: 50%;
      }
      &:nth-child(4) {
        width: 50%;
      }
      &:nth-child(5) {
        width: 50%;
      }
      &:nth-child(6) {
        width: 50%;
      }
      &:nth-child(7) {
        width: 100%;
        justify-content: flex-end;
        padding-right: 10px;
      }
    }
  }
  .table-col__inner {
    padding: 37px 15px;
    @include respondTo(1100px) {
      padding: 20px 10px;
    }
    @include respondTo(580px) {
      width: 100%;
      padding: 10px;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 20px;
      background: #f9f9f9;
      @include respondTo(1100px) {
        display: none;
      }
    }
  }
  .table-col::before {
    display: none;
  }
  .table-row {
    border: none;
  }
  td {
    padding: 0;
    padding-bottom: 20px;
    &:first-child {
      .table-col__inner {
        border-radius: 7px 0 0 7px;
      }
    }
    &:last-child {
      .table-col__inner {
        border-radius: 0 7px 7px 0;
      }
    }
  }
}
</style>
