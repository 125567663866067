<template>
  <div class='text pt-2'>
    <div class='text-content mt-5 fz-16 fz-sm-14 lh-15'>
      <div class='auth-back mb-1'>
        <a href='#' @click.prevent='$router.back()'>
          <Icon name='chevron-left' />
          <span>
          {{ $t('back') }}
        </span>
        </a>
      </div>
      <h1 class='text-title mt-2'>
        {{ $t('dataProcessingAgreement') }}
      </h1>
      <p class='fz-14 mb-3 fw-500'>
        Fecha de vigencia: 01 de enero de 2024
      </p>
      <ul class='list-number bold size-h4'>
        <li>
          Acerca de esta DPA.
          <p>
            Este Acuerdo de Procesamiento de Datos (“DPA”) es un acuerdo legal que forma parte integral y se aplica
            además de las existentes dispersión saas, S.A. y Vintrex, Virtuo, envio.click, Vintrex AI, virtuoa chat,
            vividai all dispersión saas, Acuerdo de Servicio de la Compañía S.A., Acuerdo de Socio de Ventrix AI,
            Acuerdo de Asociado de Ventas u otro acuerdo escrito o electrónico entre las partes (según corresponda)
            (“Acuerdo de Servicio”) celebrado por y entre el Cliente (como se define en el Acuerdo de Servicio) como
            controlador y dispersión. saas, S.A. Commerce Inc. y la filial dispersión saas, S.A. que es la entidad
            contratante (según se define en el Acuerdo de Servicios) (denominadas colectivamente “dispersión saas, S.A.”
            en este DPA) como procesadores en relación con la prestación de servicios , que incluyen diversos servicios
            de procesamiento de datos, al Cliente (“Servicios”). La firma del Acuerdo de Servicio se considerará que
            constituye la firma y aceptación de este DPA, que se incorpora por referencia al mismo.
          </p>
        </li>
        <li>
          Definiciones.
          <p>
            Los términos utilizados en este DPA tienen el mismo significado que los utilizados en el Acuerdo de
            servicio, a menos que se indique lo contrario. Si hay algún conflicto o inconsistencia entre el Acuerdo de
            Servicio y este DPA, este DPA prevalece.
          </p>
        </li>
        <li>
          Descripción de Datos Personales.
          <p>
            En la realización de los Servicios, dispersión saas, S.A. podrá tener acceso o de otro modo recibir o tratar
            información relativa a personas físicas identificadas o identificables (“Datos Personales”).
          </p>
          <ul class='list-lower-alpha bold'>
            <li>
              Tipo de Datos Personales tratados.
              <p>
                Dependiendo de cómo el Cliente opte por utilizar los Servicios, dispersión saas, S.A. podrá tratar los
                siguientes tipos de Datos Personales
              </p>
              <p>
                Nombre, Apellidos; Información de contacto (dirección de correo
                electrónico, domicilio, número de teléfono); Idioma; Fecha de nacimiento; Dirección IP; Datos de
                localización; Números de identificación emitidos por el gobierno; Información financiera; Detalles de
                cuenta bancaria; Informes de buró de crédito (en caso de que el Cliente haya suscrito pagos de
                dispersión saas, S.A.).
              </p>
              <p>
                dispersión saas, S.A. también puede procesar otros tipos de Datos personales si
                el Cliente ha elegido recopilar e ingresar dichos Datos personales en nuestros Servicios. Los Servicios
                no requieren otros tipos de Datos personales para funcionar correctamente. dispersión saas, S.A.
                renuncia a toda responsabilidad por daños o reclamaciones asociadas con la elección del Cliente de
                introducir Datos Personales no obligatorios en los Servicios.
              </p>
            </li>
            <li>
              Titulares de datos.
              <p>
                Se procesan datos personales sobre las siguientes categorías de personas:
              </p>
              <ul class='list-points light'>
                <li>
                  Propietario(s) de una empresa que contrata los Servicios de dispersión saas, S.A.
                </li>
                <li>
                  Empleados y otras personas autorizadas por el Cliente que tienen acceso y utilizan los Servicios
                  (Usuarios Finales).
                </li>
                <li>
                  Personas cuyos Datos personales se procesan utilizando los Servicios, incluidos los clientes y
                  proveedores de un Cliente.
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          Finalidades del tratamiento.
          <p>
            dispersión saas, S.A. es un proveedor de software como servicio para soluciones de punto de venta Vintrex,
            Virtuo, envio.click, Vintrex AI, virtuoa chat, vividai all dispersión saas, S.A. Empresa, así como una
            plataforma online que puede utilizarse para Comercio electrónico y fines relacionados. dispersión saas, S.A.
            procesará Datos personales en nombre del Cliente para brindarle estos servicios de conformidad con el
            Acuerdo de servicio y cualquier propósito adicional según las instrucciones del Cliente al utilizar los
            Servicios. Cuando dispersión saas, S.A. actúa como procesador de los Datos Personales, dispersión saas, S.A.
            sólo podrá procesar Datos Personales en nombre del Cliente y únicamente para los fines identificados en este
            DPA y el Acuerdo de Servicio.
          </p>
        </li>
        <li>
          Responsabilidades en materia de tratamiento de datos.
          <ul class='list-lower-alpha bold'>
            <li>
              Controlador.
              <p>
                El Cliente es el controlador de todos los Datos personales que recopila a través de los Servicios. El
                Cliente deberá asegurarse de que tiene derecho a procesar y transferir los Datos Personales a dispersión
                saas, S.A. para que dispersión saas, S.A. pueda procesar legalmente los Datos Personales en nombre del
                Cliente, según lo contemplado en este DPA.
              </p>
            </li>
            <li>
              Procesador.
              <p>
                dispersión saas, S.A. actúa como encargado del tratamiento de los Datos Personales recogidos por el
                Cliente mediante el uso de los Servicios.
              </p>
            </li>
            <li>
              Subencargados.
              <p>
                El Cliente reconoce y por la presente otorga su autorización expresa por escrito para que (i) las
                filiales de dispersión saas, S.A. puedan actuar como subencargadas de dispersión saas, S.A.; y (ii)
                dispersión saas, S.A. podrá contratar subencargados según sea necesario para realizar los Servicios. La
                lista de subprocesadores autorizados de dispersión saas, S.A. se puede encontrar aquí y el Cliente
                reconoce que estos subprocesadores son esenciales para proporcionar los Servicios. dispersión saas, S.A.
                informará al Cliente si añade, sustituye o cambia sus subencargados actualizando el citado listado. El
                Cliente podrá oponerse a los cambios por motivos legítimos de acuerdo con los principios de buena fe,
                razonabilidad y equidad dentro de los 30 días naturales siguientes al cambio. El Cliente reconoce que si
                se opone al uso de un subprocesador por parte de dispersión saas, S.A., dispersión saas, S.A. no estará
                obligada a proporcionar al Cliente los Servicios para los cuales dispersión saas, S.A. utiliza ese
                subprocesador.
              </p>
            </li>
          </ul>
        </li>
        <li>
          Procesamiento de datos.
          <p>
            dispersión saas, S.A. se asegurará de que cualquier procesamiento sea justo, legal y consistente con las
            obligaciones de dispersión saas, S.A. bajo este DPA y cumpla con la ley de protección de datos aplicable. En
            particular:
          </p>
          <ul class='list-lower-alpha bold'>
            <li>
              Instrucciones del controlador.
              <p>
                dispersión saas, S.A. procesará Datos Personales únicamente siguiendo las
                instrucciones documentadas del Cliente. Si se requiere que dispersión saas, S.A. procese adicionalmente
                Datos Personales de conformidad con una ley o regulación aplicable a la que esté sujeta dispersión saas,
                S.A., informará al Cliente de dicho requisito legal antes de dicho procesamiento, a menos que lo prohíba
                una
                ley aplicable. ley o reglamento;
              </p>
            </li>
            <li>
              Garantizar la protección adecuada.
              <p>
                dispersión saas, S.A. garantizará la protección adecuada de los Datos
                personales contra la destrucción accidental o ilegal o la pérdida accidental, alteración, divulgación o
                acceso no autorizado, en particular cuando el procesamiento implique una transmisión de Datos personales
                a
                través de una red, y contra todas las demás formas ilegales de procesamiento;
              </p>
            </li>
            <li>
              Salvaguardas de seguridad.
              <p>
                dispersión saas, S.A. deberá cumplir con los requisitos de seguridad
                establecidos en el Anexo 1, teniendo en cuenta el estado de la técnica, los costes de implementación y
                la
                naturaleza, alcance, contexto y finalidades del tratamiento;
              </p>
            </li>
            <li>
              Divulgación.
              <p>
                dispersión saas, S.A. no divulgará Datos Personales a terceros o personas no autorizadas, a menos que el
                Cliente haya dado su consentimiento previo por escrito para dicha divulgación y sujeto a las condiciones
                establecidas en la sección 6 de este DPA;
              </p>
            </li>
            <li>
              Confidencialidad.
              <p>
                dispersión saas, S.A. mantendrá los Datos personales en estricta confidencialidad y exigirá que los
                empleados y cualquier otra persona bajo su autoridad a quien se le proporcione acceso a los Datos
                personales o los procese de otro modo se mantengan en el mismo nivel de confidencialidad de acuerdo con
                los requisitos de la DPA. (incluso durante la duración de su empleo o compromiso y posteriormente);
              </p>
            </li>
            <li>
              Solicitudes del interesado.
              <p>
                dispersión saas, S.A. tomará las medidas adecuadas para ayudar al Cliente, en la medida de lo posible, a
                cumplir con sus obligaciones como controlador en respuesta a las solicitudes de los interesados
                individuales para ejercer sus derechos bajo cualquier ley o reglamento de protección de datos
                aplicable. Además, dispersión saas, S.A. notificará de inmediato al Cliente si recibe una solicitud de
                un individuo con respecto a Datos Personales, incluidas, entre otras, solicitudes de acceso a
                información, solicitudes de rectificación de información, solicitudes de bloqueo, eliminación o
                portabilidad de Datos Personales y no responderá a dichas solicitudes a menos que esté expresamente
                autorizado para hacerlo por el Cliente o a menos que así lo requiera una ley de protección de datos
                aplicable o una ley de la Unión Europea o de un Estado miembro a la que esté sujeta dispersión saas,
                S.A.; Además, dispersión saas, S.A. se asegurará de haber implementado medidas técnicas y organizativas
                para ayudar al Cliente a cumplir con su obligación de responder a cualquier solicitud de un individuo
                con respecto a los Datos Personales procesados.
              </p>
              <p>
                dispersión saas, S.A. atenderá pronta y adecuadamente
                las consultas y solicitudes del Cliente en relación con el procesamiento de Datos Personales bajo este
                DPA;
              </p>
            </li>
            <li>
              Asistencia con el cumplimiento del Cliente.
              <p>
                Teniendo en cuenta la naturaleza del tratamiento y la
                información de que dispone dispersión saas, S.A., dispersión saas, S.A. ayudará al Cliente a garantizar
                el
                cumplimiento de las obligaciones en materia de medidas de seguridad y a realizar evaluaciones de impacto
                en materia de protección de datos, cuando sea necesario de conformidad con los artículos 32 a 36. del
                Reglamento General de Protección de Datos (RGPD).
              </p>
              <p>
                dispersión saas, S.A. ayudará y apoyará al Cliente en
                caso de una investigación por parte de una autoridad de protección de datos o autoridad similar, si y en
                la medida en que dicha investigación se relacione con el procesamiento de Datos personales bajo este
                DPA.
              </p>
              <p>
                dispersión saas, S.A. notificará de inmediato al Cliente si, en opinión de dispersión saas, S.A., una
                instrucción dada por el Cliente infringe las leyes y regulaciones aplicables, incluidas las leyes de
                protección de datos, o si es probable que un cambio en las leyes y regulaciones aplicables tenga un
                efecto
                sustancialmente adverso. sobre su capacidad para cumplir con sus obligaciones en virtud de este DPA;
                dispersión saas, S.A. tendrá derecho a suspender la ejecución de la correspondiente instrucción hasta
                que
                sea confirmada o modificada por el Cliente. dispersión saas, S.A. podrá negarse a ejecutar una
                instrucción
                manifiestamente ilícita;
              </p>
            </li>
            <li>
              Solicitudes de divulgación.
              <p>
                En la medida permitida por la ley aplicable, dispersión saas, S.A. notificará al Cliente cada solicitud
                que dispersión saas, S.A. reciba de una autoridad pública que requiera que dispersión saas, S.A.
                divulgue Datos Personales procesados en el contexto del Acuerdo de Servicio o participe en una
                investigación. que involucre esos Datos Personales. dispersión saas, S.A. hará esfuerzos razonables para
                limitar el alcance de cualquier solicitud recibida y proporcionará únicamente los Datos Personales
                específicamente solicitados;
              </p>
            </li>
            <li>
              Violación de datos.
              <p>
                dispersión saas, S.A. deberá inmediatamente (y en cualquier caso dentro de las cuarenta y ocho (48)
                horas) después de tener conocimiento, notificar al Cliente cualquier hecho del que tenga conocimiento
                dispersión saas, S.A. relativo a cualquier acceso, divulgación o uso real, accidental o no autorizado, o
                accidental o pérdida, daño o destrucción no autorizados de Datos Personales por cualquier empleado,
                contratista o agente actual o anterior de dispersión saas, S.A. o por cualquier otra persona o tercero;
              </p>
              <p>
                dispersión saas, S.A. cooperará plenamente con el Cliente en caso de cualquier acceso, divulgación o uso
                accidental o no autorizado, o pérdida, daño o destrucción accidental o no autorizado de Datos Personales
                por parte de cualquier empleado, contratista o agente actual o anterior de dispersión saas, S.A. o por
                cualquier otra persona o tercero, con el fin de limitar la divulgación o el uso no autorizado, solicitar
                la devolución de cualquier Dato personal y ayudar a notificar a los reguladores competentes y a las
                personas afectadas si así lo solicita el Cliente.
              </p>
            </li>
          </ul>
        </li>
        <li>
          Procesamiento posterior.
          <p>
            dispersión saas, S.A. sólo podrá subcontratar la prestación de parte de los Servicios a terceros como
            subencargados (incluidas las filiales de dispersión saas, S.A. fuera del EEE, Suiza y el Reino Unido) si
            dispersión saas, S.A. garantiza que dichos subencargados están obligados a obligaciones que no sean menos
            onerosas que las establecidas en este DPA.
          </p>
        </li>
        <li>
          Conservación y supresión.
          <ul class='list-lower-alpha light'>
            <li>
              dispersión saas, S.A. procesa Datos Personales durante el tiempo que sea razonablemente necesario para
              prestar los Servicios. El plazo de retención puede ser mayor si dispersión saas, S.A. debe conservar los
              Datos Personales por más tiempo en base a la ley aplicable o para administrar su negocio.
            </li>
            <li>
              A solicitud del Cliente, dispersión saas, S.A. dejará inmediatamente de procesar Datos Personales y
              devolverá de inmediato todos dichos Datos Personales, o los eliminará, de acuerdo con las instrucciones
              que le pueda dar el Cliente en ese momento, a menos que sea requerido. almacenar los Datos Personales bajo
              una ley o regulación aplicable a la que está sujeta dispersión saas, S.A. o a menos que se acuerde
              explícitamente lo contrario con el Cliente. Las obligaciones establecidas en esta sección permanecerán
              vigentes independientemente de la terminación o vencimiento de este DPA.
            </li>
          </ul>
        </li>
        <li>
          Auditoría y Cumplimiento.
          <ul class='list-lower-alpha light'>
            <li>
              dispersión saas, S.A. pondrá a disposición del Cliente toda la información necesaria para demostrar el
              cumplimiento de las obligaciones en materia de tratamiento de Datos Personales facilitados a dispersión
              saas, S.A. en su calidad de encargado del tratamiento.
            </li>
            <li>
              dispersión saas, S.A. pondrá a disposición del Cliente o de un evaluador independiente calificado
              seleccionado por el Cliente los sistemas de procesamiento, las instalaciones y la documentación de
              respaldo relevantes para el procesamiento de Datos Personales y brindará toda la asistencia que el Cliente
              pueda razonablemente requerir para la auditoría, no más de una vez por período de 12 meses. Si la
              auditoría demuestra que dispersión saas, S.A. ha incumplido alguna obligación bajo el DPA, dispersión
              saas, S.A. subsanará inmediatamente dicho incumplimiento.
            </li>
            <li>
              En caso de inspecciones o auditorías por parte de una autoridad gubernamental competente relacionadas con
              el procesamiento de Datos Personales, dispersión saas, S.A. pondrá a disposición de la autoridad pública
              competente correspondiente sus sistemas de procesamiento, instalaciones y documentación de respaldo
              relevantes para una inspección o auditoría si esta es necesario para cumplir con las leyes aplicables. En
              caso de cualquier inspección o auditoría, cada parte deberá brindar toda la asistencia razonable a la otra
              parte para responder a esa inspección o auditoría. Si una autoridad pública competente considera ilegal el
              procesamiento de Datos personales en virtud de este DPA, las partes deberán tomar medidas inmediatas para
              garantizar el cumplimiento futuro de la ley de protección de datos aplicable. En lugar de inspecciones y
              controles in situ, dispersión saas, S.A. podrá remitir al Cliente a un control equivalente realizado por
              terceros independientes (como auditores neutrales en materia de protección de datos), al cumplimiento de
              normas de conducta aprobadas (Art. 40 RGPD) o a una adecuada protección de datos. o certificaciones de
              seguridad informática de conformidad con el art. 42 RGPD. Esto se aplica en particular si los controles
              ponen en peligro los secretos empresariales y comerciales de dispersión saas, S.A. o datos personales de
              terceros.
            </li>
            <li>
              El Cliente reembolsará a dispersión saas, S.A. cualquier costo razonable incurrido por dispersión saas,
              S.A. en relación con cualquier auditoría o inspección realizada por (o en nombre de) el Cliente o una
              autoridad gubernamental competente, excepto cuando dicha auditoría o inspección revele que dispersión
              saas, S.A. ha incumplido cualquiera de sus obligaciones bajo la DPA.
            </li>
            <li>
              Excepto cuando la ley prohíba a dispersión saas, S.A. hacer dicha divulgación, dispersión saas, S.A.
              informará de inmediato al Cliente si: (i) recibe una consulta, una citación o una solicitud de inspección
              o auditoría de una autoridad pública competente relacionada con al procesamiento de Datos personales en
              virtud de este DPA, si se trata de datos del Cliente; o (ii) tiene la intención de revelar Datos
              Personales a cualquier autoridad pública competente.
            </li>
            <li>
              dispersión saas, S.A. se asegurará de que cualquier empleado, agente, contratista independiente o
              cualquier otra persona que participe en la prestación de los Servicios y que tenga acceso a los Datos
              Personales del Cliente, cumpla con todas las leyes y regulaciones de protección de datos y privacidad
              (incluyendo todas y cada una de las modificaciones legislativas y/o reglamentarias o sucesoras de las
              mismas), aplicables a dispersión saas, S.A.
            </li>
          </ul>
        </li>
        <li>
          Transferencias de datos (sólo para Clientes establecidos en el EEE, Suiza o el Reino Unido).
          <ul class='list-lower-alpha light'>
            <li>
              El cliente autoriza a dispersión saas, S.A. a encargar el tratamiento en un tercer país, incluso por
              subencargados, si se cumplen los requisitos específicos de los artículos 44-49 RGPD. Se considerará que el
              Cliente ha otorgado su consentimiento explícito para el procesamiento en un tercer país con respecto a las
              operaciones de procesamiento por dispersión saas, S.A. y los Subencargados Autorizados que se enumeran a
              continuación.
            </li>
            <li>
              dispersión saas, S.A. Commerce Inc. es una empresa con sede en Canadá. Como tal, la mayoría de las
              transferencias de datos de Clientes establecidos en el Espacio Económico Europeo (EEE), Suiza o el Reino
              Unido (UK) a dispersión saas, S.A. se realizan de conformidad con la
              <a href='https://eur-lex.europa.eu/legal-content/en/TXT/?uri=CELEX%3A32002D0002' target='_blank'>decisión
                de adecuación</a>
              de la Comisión
              Europea para Canadá.
            </li>
            <li>
              En la medida en que la decisión de adecuación no aplique, dispersión saas, S.A. se basa en las Cláusulas
              Contractuales Tipo (“CEC”) adjuntas, adjuntas al presente como Anexo 2, como mecanismo de transferencia
              aprobado para transferencias internacionales de Datos Personales. En estas SCC, el Cliente es el
              exportador de datos y dispersión saas, S.A. Commerce Inc. es el importador de datos.
            </li>
            <li>
              La firma del Acuerdo de Servicio se considerará firma y aceptación de las CEC. Si el Cliente desea
              ejecutar adicionalmente una copia separada de las CEC, el Cliente puede completar la versión prefirmada
              correspondiente adjunta al presente como Anexo 2, refrendarla y devolverla a dispersión saas, S.A. por
              correo electrónico a privacidad@dispersión.com indicando, en su caso, la entidad jurídica y/o número de
              cuenta del Cliente (mencionado en el Formulario de Pedido o factura de dispersión saas, S.A.
              correspondiente).
            </li>
            <li>
              En ausencia de las salvaguardias adecuadas antes mencionadas, dispersión saas, S.A. puede, en la medida
              permitida por y de conformidad con las leyes de protección de datos aplicables (incluido el RGPD),
              acogerse a una derogación aplicable a la situación específica en cuestión (por ejemplo, los interesados '
              consentimiento explícito, la necesidad para la ejecución de un acuerdo, la necesidad para el
              establecimiento, ejercicio o defensa de reclamaciones legales).
            </li>
            <li>
              En relación con las transferencias de datos personales protegidos por la Ley de Protección de Datos del
              Reino Unido de 2018, se aplican las CEC y se consideran modificadas según lo especificado en el Anexo del
              Reino Unido, que se considera ejecutado por las partes e incorporado por referencia a este DPA. Cualquier
              conflicto entre los términos de las CEC y el Anexo del Reino Unido se resolverá de conformidad con la
              Sección 10 y la Sección 11 del Anexo del Reino Unido.
            </li>
          </ul>
        </li>
        <li>
          Consultas de datos.
          <p>
            Cualquier Cliente podrá, en cualquier momento, dirigirse a dispersión saas, S.A. en el correo
            privacidad@dispersión.com para todas aquellas dudas y sugerencias en materia de protección de datos.
          </p>
        </li>
        <li>
          Provisiones generales.
          <ul class='list-lower-alpha bold'>
            <li>
              Enmiendas.
              <p>
                Cualquier modificación o complemento a este DPA debe realizarse por escrito. Lo mismo se aplica a
                cualquier renuncia a cualquier derecho u obligación en virtud de este DPA. Esto no afectará al orden de
                precedencia de los acuerdos contractuales individuales. dispersión saas, S.A. se reserva el derecho de
                modificar el presente DPA en cualquier momento con efectos para el futuro. Sólo se realizarán
                modificaciones si concurren las siguientes razones objetivas:
              </p>
              <ul class='list-points light'>
                <li>
                  si la modificación contribuye a que la DPA se ajuste a la legislación aplicable, en particular si
                  cambia la situación jurídica aplicable;
                </li>
                <li>
                  si la modificación permite a dispersión saas, S.A. cumplir con decisiones judiciales o administrativas
                  obligatorias
                </li>
                <li>
                  si la modificación refleja detalles de un Servicio nuevo o actualizado de dispersión saas, S.A. o de
                  procesos técnicos u organizativos nuevos o actualizados y la relación contractual existente con el
                  Cliente no se ve afectada en perjuicio del Cliente;
                </li>
                <li>
                  si la modificación es únicamente en beneficio del Cliente.
                </li>
              </ul>
            </li>
            <li>
              Divisibilidad.
              <p>
                Si alguna disposición de este Acuerdo es o pasa a ser inválida o impracticable en su totalidad o en
                parte, la validez de las disposiciones restantes no se verá afectada por ello.
              </p>
            </li>
            <li>
              Plazo.
              <p>
                Este DPA será efectivo durante todo el Plazo (según se define en el Acuerdo de servicio) y este DPA
                finaliza en la fecha en que el Acuerdo de servicio haya expirado o finalizado.
              </p>
            </li>
          </ul>
        </li>
      </ul>
      <h2 class='text-center'>
        Anexo 1: Descripción de las Medidas de Seguridad de dispersión saas, S.A.
      </h2>
      <p>
        dispersión saas, S.A. ha tomado medidas de seguridad técnicas y organizativas apropiadas y suficientes para
        proteger los Datos Personales contra la destrucción accidental o ilegal o la pérdida accidental, alteración,
        divulgación o acceso no autorizado, en particular cuando el procesamiento implica una transmisión de Datos
        Personales a través de una red, y contra todas las demás formas ilícitas de tratamiento.
      </p>
      <p>
        dispersión saas, S.A. cuenta con una organización de seguridad de la información consolidada, gestionada por el
        equipo de seguridad de dispersión saas, S.A. y está liderada por el Vicepresidente de Seguridad de la
        Información. dispersión saas, S.A. Seguridad establece y mantiene políticas y procedimientos para delinear
        estándares de acceso lógico a los ambientes productivos de dispersión saas, S.A. Las políticas también
        identifican responsabilidades funcionales para la administración del acceso lógico y la seguridad. Las políticas
        de Seguridad de la Información de dispersión saas, S.A. son revisadas y aprobadas anualmente por Security
        Leadership y se utilizan para apoyar a dispersión saas, S.A. en el cumplimiento de los compromisos de servicio
        adquiridos con el Cliente.
      </p>
      <p>
        La siguiente descripción proporciona una descripción general de las medidas de seguridad técnicas y
        organizativas implementadas. Dichas medidas incluirán, entre otras, las siguientes. Para obtener información más
        detallada sobre las últimas medidas de última generación, contáctenos directamente.
      </p>
      <h3>
        Protección de Datos
      </h3>
      <p>
        dispersión saas, S.A. procesará los Datos Personales como procesador de Datos, únicamente con el fin de
        proporcionar los Servicios de acuerdo con instrucciones documentadas del Cliente (siempre que dichas
        instrucciones sean proporcionales a las funcionalidades de los Servicios), y según se pueda acordar. con el
        Cliente.
      </p>
      <p>
        dispersión saas, S.A. implementa y mantiene medidas técnicas y organizativas apropiadas para proteger los Datos
        Personales contra tratamientos no autorizados o ilícitos y contra pérdida accidental, destrucción, daño, robo,
        alteración o divulgación.
      </p>
      <p>
        dispersión saas, S.A. mantiene un marco de gestión de riesgos y lo revisa al menos trimestralmente para
        identificar cambios en su entorno, sistemas y panorama de amenazas para identificar y gestionar cualquier riesgo
        involucrado con las operaciones y el procesamiento de los Datos Personales.
      </p>
      <p>
        dispersión saas, S.A. garantiza que su personal que accede a los Datos Personales esté sujeto a obligaciones de
        confidencialidad que restringen su capacidad de revelar los Datos Personales, y se somete a capacitaciones
        anuales en concientización sobre seguridad de la información.
      </p>
      <p>
        dispersión saas, S.A. emplea los conceptos de privilegio mínimo y necesidad de saber, permitiendo únicamente el
        acceso necesario a los usuarios para realizar su función laboral. Las cuentas de usuario se crean para tener un
        acceso mínimo. El acceso por encima de estos privilegios mínimos requiere una autorización adecuada y separada.
      </p>
      <p>
        dispersión saas, S.A. aplica la autenticación multifactor en todas las aplicaciones e infraestructuras críticas.
      </p>
      <p>
        <b>En tránsito:</b> dispersión saas, S.A. implementa cifrado HTTPS en todas sus interfaces de inicio de sesión y
        en cada sitio de cliente alojado en los productos de dispersión saas, S.A. La implementación HTTPS de dispersión
        saas, S.A. utiliza algoritmos y certificados estándar de la industria.
      </p>
      <p>
        <b>En reposo:</b> dispersión saas, S.A. implementa un cifrado en reposo para proteger contra la pérdida de
        datos.
      </p>
      <h3>
        Control de acceso
      </h3>
      <ul class='list-number bold size-h4'>
        <li>
          Prevención del acceso no autorizado al producto
          <ul class='list-lower-alpha bold'>
            <li>
              Tratamiento externalizado:
              <p>
                dispersión saas, S.A. aloja sus servicios en infraestructuras de Hosting de
                terceros en forma de centros de datos e Infraestructura como Servicio (IaaS). Adicionalmente, dispersión
                saas, S.A. mantiene relaciones contractuales con proveedores con el fin de prestar el servicio de
                acuerdo
                con nuestro DPA. dispersión saas, S.A. se basa en acuerdos contractuales, políticas de privacidad y
                programas de cumplimiento de proveedores para proteger los datos procesados o almacenados por estos
                proveedores.
              </p>
            </li>
            <li>
              Seguridad física y ambiental:
              <p>
                dispersión saas, S.A. aloja su infraestructura de productos con proveedores
                de infraestructura subcontratados multiinquilino. Los controles de seguridad física y ambiental de
                nuestros proveedores de infraestructura son auditados para el cumplimiento de SOC 2 Tipo II, ISO 27001 y
                PCI DSS, entre otras certificaciones.
              </p>
            </li>
            <li>
              Autenticación:
              <p>
                dispersión saas, S.A. implementó un fuerte mecanismo de autenticación para los usuarios de dispersión
                saas, S.A. que acceden a los productos de sus clientes. Todos los usuarios de dispersión saas, S.A. que
                necesiten interactuar con los productos a través de cualquier interfaz deberán autenticarse mediante
                autenticación multifactor para poder acceder a datos no públicos del Cliente.
              </p>
            </li>
            <li>
              Autorización:
              <p>
                los datos del Cliente se almacenan en sistemas de almacenamiento multiinquilino a los que los Clientes
                pueden acceder únicamente a través de interfaces de usuario de aplicaciones e interfaces de programación
                de aplicaciones. Los clientes no tienen acceso directo a la infraestructura de la aplicación subyacente.
                El modelo de autorización en cada uno de los productos de dispersión saas, S.A. está diseñado para
                garantizar que solo las personas asignadas adecuadamente puedan acceder a funciones, vistas y opciones
                de personalización relevantes. La autorización de los conjuntos de datos se realiza mediante la
                validación de los permisos del usuario con respecto a los atributos asociados con cada conjunto de
                datos.
              </p>
            </li>
          </ul>
        </li>
        <li>
          Preventing Unauthorized Product Use
          <ul class='list-lower-alpha bold'>
            <li>
              Prevención del uso no autorizado del producto:
              <p>
                Controles de acceso: los mecanismos de control de acceso a la red están diseñados para evitar que el
                tráfico de red que utiliza protocolos no autorizados llegue a la infraestructura del producto. Las
                medidas técnicas implementadas difieren entre los proveedores de infraestructura e incluyen
                implementaciones de Nube Privada Virtual (VPC), asignación de grupos de seguridad y reglas de firewall
                tradicionales.
              </p>
            </li>
            <li>
              Detección y prevención de intrusiones:
              <p>
                dispersión saas, S.A. implementó una solución Web Application
                Firewall (WAF) para proteger ciertos sitios web alojados de clientes y otras aplicaciones accesibles a
                través de Internet identificadas específicamente por dispersión saas, S.A.. El WAF está diseñado para
                detectar y prevenir ataques contra dominios públicos. servicios disponibles.
              </p>
            </li>
            <li>
              Escaneo de vulnerabilidades:
              <p>
                dispersión saas, S.A. escanea periódicamente su código, infraestructura y
                servicios web en busca de vulnerabilidades conocidas y las remedia de manera oportuna. dispersión saas,
                S.A. se suscribe a fuentes de noticias para detectar fallas aplicables de los proveedores y monitorea
                proactivamente los sitios web de los proveedores y otros medios relevantes para detectar nuevos parches.
              </p>
            </li>
          </ul>
        </li>
        <li>
          Limitaciones de privilegios y requisitos de autorización
          <ul class='list-lower-alpha bold'>
            <li>
              Acceso a los productos:
              <p>
                Un subconjunto de empleados de dispersión saas, S.A. tiene acceso a los productos
                y a los datos de los clientes a través de interfaces controladas. La intención de brindar acceso a un
                subconjunto de empleados es brindar soporte efectivo, solucionar problemas potenciales, detectar y
                responder a incidentes de seguridad e implementar la seguridad de los datos. A los empleados se les
                puede
                otorgar acceso por función o al enviar una solicitud aprobada. Se registran los inicios de sesión en
                sistemas de almacenamiento o procesamiento de datos.
              </p>
            </li>
            <li>
              Acceso a la base de datos:
              <p>
                los datos del cliente son accesibles y manejables solo por un número limitado
                de personal autorizado. El acceso a los datos está restringido mediante la segmentación de la red de los
                entornos de producción, puesta en escena, control de calidad y desarrollo. El acceso directo a consultas
                de bases de datos está restringido y se establecen y aplican derechos de acceso a las aplicaciones.
              </p>
            </li>
          </ul>
        </li>
      </ul>
      <h3>
        Control de Gestión de Incidentes
      </h3>
      <ul class='list-number bold size-h4 mb-5'>
        <li>
          Detección:
          <p>
            dispersión saas, S.A. diseñó su infraestructura para registrar amplia información sobre el comportamiento
            del sistema, tráfico recibido, autenticación del sistema y otras solicitudes de aplicaciones. Los sistemas
            internos agregan datos de registro y alertan a los empleados correspondientes sobre actividades maliciosas,
            no intencionadas o anómalas. El personal de dispersión saas, S.A., incluido el personal de seguridad,
            operaciones y soporte, está atento a los incidentes conocidos.
          </p>
        </li>
        <li>
          Respuesta y seguimiento:
          <p>
            dispersión saas, S.A. mantiene un registro de incidentes de seguridad conocidos que incluye descripciones,
            fechas y horas de actividades relevantes y remediación de incidentes. Los incidentes de seguridad
            sospechosos y confirmados son investigados por el personal de seguridad, operaciones o soporte, y se
            identifican y documentan los pasos de resolución adecuados. Ante cualquier incidencia confirmada, dispersión
            saas, S.A. tomará las medidas oportunas para minimizar los daños al producto y al Cliente o su divulgación
            no autorizada.
          </p>
        </li>
        <li>
          Comunicación:
          <p>
            Si dispersión saas, S.A. tuviera conocimiento de un acceso ilícito a datos del Cliente almacenados en sus
            productos, dispersión saas, S.A.: Notificará la incidencia a los Clientes afectados; Describa las
            actuaciones que está realizando dispersión saas, S.A. para solucionar la incidencia; Proporcionar
            actualizaciones de estado al contacto del Cliente, según lo considere necesario o sea legalmente requerido.
            La notificación de incidencias, en su caso, se entregará a uno o varios contactos del Cliente en la forma
            que dispersión saas, S.A. seleccione, que podrá incluir vía correo electrónico o teléfono.
          </p>
        </li>
      </ul>
      <DataTable
        :headers='tableHeaders'
        :list='list'>
      </DataTable>
      <div class='pb-5 mb-5'></div>
    </div>
  </div>
</template>
<script setup>
import Icon from '@/components/atoms/Icon.vue';
import DataTable from '@/components/features/DataTable.vue';

const tableHeaders = [
  {
    text: 'Sub-Processors',
    value: 'processors'
  },
  {
    text: 'Associated',
    value: 'associated'
  },
  {
    text: 'Location',
    value: 'location'
  }
];
const list = [
  {
    id: 1,
    processors: 'Amazon Web Services, Inc.',
    associated: 'All Services',
    location: 'USA'
  },
  {
    id: 2,
    processors: 'Auth0, Inc.',
    associated: 'Various',
    location: 'USA'
  },
  {
    id: 3,
    processors: 'Cayan (TSYS)',
    associated: 'All Services',
    location: 'USA'
  },
  {
    id: 4,
    processors: 'Google LLC',
    associated: 'Various',
    location: 'USA'
  },
  {
    id: 5,
    processors: 'Stripe, Inc.',
    associated: 'Various',
    location: 'USA'
  },
  {
    id: 6,
    processors: 'Twilio Inc.',
    associated: 'Various',
    location: 'USA'
  },
  {
    id: 7,
    processors: 'Worldpay',
    associated: 'Various',
    location: 'USA'
  }
];

</script>
